import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import ErrorPage from "../pages/not_found";
import Root from "./root";
import Spectacle from "../pages/spectacle";
import React from "react";
import Accueil from "../pages/accueil";
import Atelier from "../pages/atelier";
import ArtEtSoin from "../pages/art_et_soin";
import AmbSengage from "../pages/amb_s_engage";
import BoutonDart from "../pages/bouton_d_art";
import LaCompagnie from "../pages/la_compagnie";
import LaPresseEnParle from "../pages/la_presse_en_parle";
import Header from "../decorators/header";
import {Footer} from "../decorators/footer/Footer";
import { Outlet } from 'react-router-dom';
import BoutonDartDetail from "../pages/bouton_d_art_detail";
import ArtisteDetail from "../pages/artiste_detail";
import AtelierDetail from "../pages/atelier_detail";
import SpectacleCatalogue from "../pages/spectacle_catalogue";
import SpectacleDetail from "../pages/spectacle_detail";
import ArtEtSoinDetail from "../pages/art_et_soin_detail";
import AmbSengageDetail from "../pages/amb_s_engage_detail";


function Layout({children}) {
  return (
    <>
    <Header />
      <div className="container">
      <Outlet />
      </div>
    <Footer/>
    </>
  );
}

const router = createBrowserRouter([
  {path: "/",
  element: <Layout/>,
  children: [
  {
    path: "/",
    element: <Accueil />,

  },
  {
    path: "/accueil",
    element: <Accueil/>,
  },
  {
    path: "/spectacles",
    element: <Spectacle />,
  },
  {
    path: "/spectacles/:id",
    element: <SpectacleDetail />,
  },
  {
    path: "/spectacles/categorie/:name",
    element: <SpectacleCatalogue />,
  },
  {
    path: "/ateliers",
    element: <Atelier/>,
  },
  {
      path: "/ateliers/:id",
    element: <AtelierDetail/>,
  },
  {
    path: "/art-et-soin/:id",
    element: <ArtEtSoinDetail/>,
  },
  {
    path: "/art-et-soin",
    element: <ArtEtSoin/>,
  },
  {
    path: "/amb-s-engage/:id",
    element: <AmbSengageDetail/>,
  },
  {
    path: "/amb-s-engage",
    element: <AmbSengage/>,
  },
  {
  path: "/boutons-d-art/:id",
  element: <BoutonDartDetail/>,
  },
  {
    path: "/boutons-d-art",
    element: <BoutonDart/>,
  },
  {
    path: "/compagnie",
    element: <LaCompagnie/>,
  },
  {
    path: "/artiste/:id",
    element: <ArtisteDetail/>,
  },
  {
    path: "/presse",
    element: <LaPresseEnParle/>,
  }],
    errorElement: <ErrorPage />,
  }
]);

export default router;
