import React from 'react';
import './NewsletterHandler.scss';
import Slider from "react-slick";
import NewsletterInfo from "../newsletter-info";
import useFetch from "../../hooks/useFecth";
import Atelier from "../../models/Atelier";
import Loader from "../loader";

export const NewsletterHandler = () => {

  var settings = {
    accessibility: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2500,
    swipeToSlide: true,
    pauseOnHover: true,
    fade: false,
  };

  const { data, loading, error } = useFetch<number>('/newsletters/');

  return (
    <>
      {loading && <Loader/>}
      {error && <p>Erreur lors du chargement des données</p>}
      {data &&
        <div className={"newsletter-handler"}>
          <Slider {...settings}>
            {data.map(id => (
              <NewsletterInfo key={id} id={id}/>
            ))}
          </Slider>
        </div>
      }
    </>

  );
};
